import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SingleDatasetChart from '@components/chart/SingleDatasetChart'

const ByWasteFlow = ({
  getValues,
  settings,
  StyledCard,
}: {
  getValues: any
  settings: any
  StyledCard: any
}) => {
  const { t } = useTranslation()
  const values = getValues()
  let resKey = 'cdWasteGenerationByWasteFlow'
  const byWasteFlowData = values?.project?.[resKey]?.datasets
  const hideChart = values?.project?.[resKey]?.showChart === false
  const message = values?.project?.[resKey]?.message ?? ''
  const inertByWasteFlowData = byWasteFlowData?.find((item) => item.groupName === 'Inert')?.data
  const noninertByWasteFlowData = byWasteFlowData?.find(
    (item) => item.groupName === 'Non-Inert',
  )?.data

  const modifiedByWasteFlowData: any = {}

  inertByWasteFlowData?.map((item) => {
    const groupName = 'Inert'
    const itemName = item.itemName
    const itemValue = item.value // for percentage float value
    if (!modifiedByWasteFlowData[groupName]) {
      modifiedByWasteFlowData[groupName] = {}
    }
    modifiedByWasteFlowData[groupName][itemName] = itemValue
  })

  noninertByWasteFlowData?.map((item) => {
    const groupName = 'Non-Inert'
    const itemName = item.itemName
    const itemValue = item.value
    if (!modifiedByWasteFlowData[groupName]) {
      modifiedByWasteFlowData[groupName] = {}
    }
    modifiedByWasteFlowData[groupName][itemName] = itemValue
  })

  const inertBorderColors = settings?.[resKey]?.['Inert']?.map((item) => item.borderColor)
  const inertBackgroundColors = settings?.[resKey]?.['Inert']?.map((item) => item.backgroundColor)
  const nonInertBorderColors = settings?.[resKey]?.['Non-Inert']?.map((item) => item.borderColor)
  const nonInertBackgroundColors = settings?.[resKey]?.['Non-Inert']?.map(
    (item) => item.backgroundColor,
  )

  return (
    <>
      <h2>{t('dashboard:totalCDByWasteFlow')}</h2>

      <Grid container md={12} xs={12}>
        {hideChart ? (
          <>{t(message)}</>
        ) : (
          <>
            <Grid item md={6} xs={12}>
              {/* <StyledCard variant="outlined"> */}
              <SingleDatasetChart
                eleBefore={
                  <>
                    <h4>{t('dashboard:inert')}</h4>
                  </>
                }
                chartWrapperSX={{
                  width: '75%',
                  margin: 'auto',
                }}
                type={'pie'}
                hasSummaryTable={false}
                data={modifiedByWasteFlowData?.['Inert'] ?? {}}
                dataConfig={{
                  borderColor: inertBorderColors,
                  borderWidth: 2,
                  backgroundColor: inertBackgroundColors,
                }}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      enabled: true,
                      intersect: false,
                      mode: 'nearest',
                      callbacks: {
                        label: (item) => {
                          return t(item.label) + ': ' + item.parsed * 100 + '%'
                        },
                      },
                    },
                    datalabels: {
                      formatter: (value, ctx) => {
                        let percentage = (value * 100).toFixed(2) + '%'
                        if (percentage === '0.00%') {
                          percentage = ''
                        }
                        return percentage
                      },
                      color: '#fff',
                    },
                  },
                }}></SingleDatasetChart>
              {/* </StyledCard> */}
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <StyledCard variant="outlined"> */}
              <SingleDatasetChart
                eleBefore={
                  <>
                    <h4>{t('dashboard:nonInert')}</h4>
                  </>
                }
                chartWrapperSX={{
                  width: '75%',
                  margin: 'auto',
                }}
                type={'pie'}
                hasSummaryTable={false}
                data={modifiedByWasteFlowData?.['Non-Inert'] ?? {}}
                dataConfig={{
                  borderColor: nonInertBorderColors,
                  borderWidth: 2,
                  backgroundColor: nonInertBackgroundColors,
                }}
                options={{
                  plugins: {
                    legend: {
                      display: true,
                      position: 'bottom',
                    },
                    tooltip: {
                      enabled: true,
                      intersect: false,
                      mode: 'nearest',
                      callbacks: {
                        label: (item) => {
                          return t(item.label) + ': ' + item.parsed * 100 + '%'
                        },
                      },
                    },
                    datalabels: {
                      formatter: (value, ctx) => {
                        let percentage = (value * 100).toFixed(2) + '%'
                        if (percentage === '0.00%') {
                          percentage = ''
                        }
                        return percentage
                      },
                      color: '#fff',
                    },
                  },
                }}></SingleDatasetChart>
              {/* </StyledCard> */}
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
export default ByWasteFlow
