import MultiTypeDatasetChart, {
  generalChartDatasetProps,
} from '@components/chart/MultiTypeDatasetChart'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
const ByMonthWasteFlow = ({
  getValues,
  settings,
  StyledCard,
}: {
  getValues: any
  settings: any
  StyledCard: any
}) => {
  const { t } = useTranslation()
  let chartData: generalChartDatasetProps[] = []

  const values = getValues() // TODO: change to this one
  const resKey = 'cdWasteGenerationByMonthAndFlow'
  const resData = values?.project?.[resKey]?.datasets
  const chartSettings = settings?.[resKey]
  const hideChart = values?.project?.[resKey]?.showChart === false
  const message = values?.project?.[resKey]?.message ?? ''
  const timeFormattedData = resData?.map((item) => {
    const formattedValue: { [key: string]: any } = {}
    item.data?.map((item: any) => {
      const formattedDate = moment(item.itemName).format('MMM YYYY')
      formattedValue[formattedDate] = item.value // for percentage float value
    })

    return { groupName: item.groupName, value: formattedValue }
  })

  const processChartData = (chartData: any[]) => {
    timeFormattedData?.map((item: any) => {
      let obj: generalChartDatasetProps = {
        type: 'bar',
        label: t(item.groupName),
        data: item.value,
        yAxisID: 'y',
        borderColor: chartSettings?.[item.groupName]?.borderColor,
        backgroundColor: chartSettings?.[item.groupName]?.backgroundColor,
      }
      chartData.push(obj)
    })
  }

  processChartData(chartData)

  let multiTypeDatasetChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        reverse: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 10,
        },
        fullSize: false, // set fullSize to false
      },
      //TODO:  Add a custom plugin to display the total value on top of each bar
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
      },
    },
  }

  return (
    <>
      <h2>{t('dashboard:totalCDByMonthWasteFlow')}</h2>
      <Grid container md={12} xs={12}>
        <Grid item md={12} xs={12}>
          {hideChart ? (
            <>{t(message)}</>
          ) : (
            <MultiTypeDatasetChart
              eleBefore={
                <>
                  <span>{t('common:units.tonnes')}</span>
                </>
              }
              chartWrapperSX={{
                width: '100%',
                minHeight: 350,
                margin: 'auto',
              }}
              datasets={chartData}
              options={multiTypeDatasetChartOptions}></MultiTypeDatasetChart>
          )}
        </Grid>
      </Grid>
    </>
  )
}
export default ByMonthWasteFlow
