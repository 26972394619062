import SingleDatasetChart from '@components/chart/SingleDatasetChart'
import { Card, Grid, Box } from '@mui/material'
import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import SwitchTabs from '@components/dashboard/switchTabs'
import DoughnutChart from '@components/chart/pie/DoughnutChart'

type tabProps = () => {
  menuTitle: string
  title: string
  content: ReactElement
}

const DashboardProjectRecyclingRate = ({
  getValues,
  settings,
  StyledCard,
  StyledBox,
}: {
  getValues: any
  settings: any
  StyledCard: any
  StyledBox: any
}) => {
  const { t } = useTranslation()
  // const values = getValues() // TODO: change to this one

  const values = getValues()
  // resKey by contract
  const resKey = 'recyclingRate'
  const resData = values?.project?.[resKey]?.datasets
  const outerData = resData?.find((item) => item.groupName === 'Outer')?.data
  const innerData = resData?.find((item) => item.groupName === 'Inner')?.data //TODO: inner ring

  let innerModifiedData = innerData?.map((item) => item.value)

  innerModifiedData ? (innerModifiedData = [innerModifiedData[0], 1 - innerModifiedData[0]]) : ''

  const outerModifiedData = outerData?.map((item) => item.value).reverse()
  const byContractLabels = outerData?.map((item) => item.itemName)

  // resKey by Average
  const resKey1 = 'recyclingRateOfSameProjectTypeAndStage'
  const resData1 = values?.project?.[resKey1]?.datasets
  const outerData1 = resData1?.find((item) => item.groupName === 'Outer')?.data
  const innerData1 = resData1?.find((item) => item.groupName === 'Inner')?.data //TODO: inner ring

  let innerModifiedData1 = innerData1?.map((item) => item.value)

  innerModifiedData1
    ? (innerModifiedData1 = [innerModifiedData1[0], 1 - innerModifiedData1[0]])
    : ''

  const outerModifiedData1 = outerData1?.map((item) => item.value).reverse()
  const byAverageLabels = outerData?.map((item) => item.itemName)

  const hideChart = values?.project?.[resKey]?.showChart === false
  const message = values?.project?.[resKey]?.message ?? ''

  // let data = [
  //   {
  //     title: '',
  //     menuTitle: t('dashboard:byContract'),
  //     content: (
  //       <DoughnutChart
  //         labels={byContractLabels}
  //         datasets={[
  //           {
  //             name: 'Outer Ring',
  //             data: outerModifiedData,
  //             dataConfig: {
  //               borderColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
  //               borderWidth: 2,
  //               backgroundColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
  //             },
  //           },
  //           {
  //             name: 'Inner Ring',
  //             data: innerModifiedData,
  //             dataConfig: {
  //               borderColor: ['transparent', 'rgb(186,188,190)'],
  //               borderWidth: 2,
  //               backgroundColor: ['transparent', 'purple'],
  //             },
  //           },
  //         ]}
  //         options={{
  //           responsive: true,
  //           plugins: {
  //             tooltip: {
  //               enabled: true,
  //               intersect: false,
  //               mode: 'nearest',
  //               callbacks: {
  //                 label: (item) => {
  //                   // Get the dataset label
  //                   let label = item.dataset.label || ''
  //                   // Get the index of the current data point
  //                   const dataIndex = item.dataIndex
  //                   console.log(item.chart.$datalabels._datasets[item.datasetIndex])

  //                   // Customize label based on dataset and index
  //                   if (item.datasetIndex === 0) {
  //                     // Outer ring
  //                     label = `${outerData[dataIndex].itemName}` // Use outer ring data
  //                   } else if (item.datasetIndex === 1) {
  //                     // Inner ring
  //                     if (dataIndex !== 0) {
  //                       label = `${innerData[0]?.itemName}`
  //                     } else {
  //                       label = `` // Use inner ring label
  //                     }
  //                   }

  //                   return t(label) + ': ' + item.parsed * 100 + '%'
  //                 },
  //               },
  //             },
  //             legend: {
  //               display: true,
  //               position: 'bottom',
  //             },
  //             datalabels: {
  //               formatter: (value, ctx) => {
  //                 let percentage = (value * 100).toFixed(2) + '%'
  //                 return percentage
  //               },
  //               color: '#fff',
  //             },
  //           },
  //         }}></DoughnutChart>
  //     ),
  //   },
  //   {
  //     title: '',
  //     menuTitle: t('dashboard:byAverage'),
  //     content: (
  //       <DoughnutChart
  //         labels={byAverageLabels}
  //         datasets={[
  //           {
  //             name: 'Outer Ring',
  //             // data: [30, 50, 20]
  //             data: outerModifiedData1,
  //             dataConfig: {
  //               borderColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
  //               borderWidth: 2,
  //               backgroundColor: ['rgb(186,188,190)', 'rgb(102,154,83)', 'rgb(178,204,169)'],
  //             },
  //           },
  //           {
  //             name: 'Inner Ring',
  //             data: innerModifiedData1,
  //             dataConfig: {
  //               borderColor: ['transparent', 'rgb(186,188,190)'],
  //               borderWidth: 2,
  //               backgroundColor: ['transparent', 'purple'],
  //             },
  //           },
  //         ]}
  //         options={{
  //           responsive: true,
  //           plugins: {
  //             tooltip: {
  //               enabled: true,
  //               intersect: false,
  //               mode: 'nearest',
  //               callbacks: {
  //                 label: (item) => {
  //                   // Get the dataset label
  //                   let label = item.dataset.label || ''
  //                   // Get the index of the current data point
  //                   const dataIndex = item.dataIndex
  //                   console.log(item.chart.$datalabels._datasets[item.datasetIndex])

  //                   // Customize label based on dataset and index
  //                   if (item.datasetIndex === 0) {
  //                     // Outer ring
  //                     label = `${outerData[dataIndex].itemName}` // Use outer ring data
  //                   } else if (item.datasetIndex === 1) {
  //                     // Inner ring
  //                     if (dataIndex !== 0) {
  //                       label = `${innerData[0]?.itemName}`
  //                     } else {
  //                       label = `` // Use inner ring label
  //                     }
  //                   }

  //                   return t(label) + ': ' + item.parsed * 100 + '%'
  //                 },
  //               },
  //             },
  //             legend: {
  //               display: true,
  //               position: 'bottom',
  //             },
  //             datalabels: {
  //               formatter: (value, ctx) => {
  //                 let percentage = (value * 100).toFixed(2) + '%'
  //                 return percentage
  //               },
  //               color: '#fff',
  //             },
  //           },
  //         }}></DoughnutChart>
  //     ),
  //   },
  // ]

  return (
    <>
      <Grid container md={12} xs={12}>
        <Grid item md={3.6} xs={12} sx={{ margin: '0 auto' }}>
          <h2>{t('dashboard:byContract')}</h2>
          {hideChart ? (
            <>{t(message)}</>
          ) : (
            <DoughnutChart
              labels={byContractLabels}
              datasets={[
                {
                  name: 'Outer Ring',
                  data: outerModifiedData,
                  dataConfig: {
                    borderColor: ['rgb(102,154,83)', 'rgb(178,204,169)', 'rgb(186,188,190)'],
                    borderWidth: 2,
                    backgroundColor: ['rgb(102,154,83)', 'rgb(178,204,169)', 'rgb(186,188,190)'],
                  },
                },
                {
                  name: 'Inner Ring',
                  data: innerModifiedData,
                  dataConfig: {
                    borderColor: ['rgb(173, 220, 124)', 'transparent'],
                    borderWidth: 2,
                    backgroundColor: ['rgb(173, 220, 124)', 'transparent'],
                  },
                },
              ]}
              options={{
                responsive: true,
                plugins: {
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    callbacks: {
                      label: (item) => {
                        // Get the dataset label
                        let label = item.dataset.label || ''
                        // Get the index of the current data point
                        const dataIndex = item.dataIndex
                        console.log(item.chart.$datalabels._datasets[item.datasetIndex])

                        // Customize label based on dataset and index
                        if (item.datasetIndex === 0) {
                          // Outer ring
                          label = `${outerData[dataIndex].itemName}` // Use outer ring data
                        } else if (item.datasetIndex === 1) {
                          // Inner ring
                          if (dataIndex === 0) {
                            label = `${innerData[0]?.itemName}`
                          } else {
                            label = `` // Use inner ring label
                          }
                        }

                        return t(label) + ': ' + item.parsed * 100 + '%'
                      },
                    },
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      let percentage = (value * 100).toFixed(2) + '%'
                      return percentage
                    },
                    color: '#fff',
                    font: {
                      size: 13,
                      weight: 'bold',
                    },
                  },
                },
              }}></DoughnutChart>
          )}
        </Grid>
        <Grid item md={3.6} xs={12} sx={{ margin: '0 auto' }}>
          <h2>{t('dashboard:byAverage')}</h2>
          {hideChart ? (
            <>{t(message)}</>
          ) : (
            <DoughnutChart
              labels={byAverageLabels}
              datasets={[
                {
                  name: 'Outer Ring',
                  // data: [30, 50, 20]
                  data: outerModifiedData1,
                  dataConfig: {
                    borderColor: ['rgb(102,154,83)', 'rgb(178,204,169)', 'rgb(186,188,190)'],
                    borderWidth: 2,
                    backgroundColor: ['rgb(102,154,83)', 'rgb(178,204,169)', 'rgb(186,188,190)'],
                  },
                },
                {
                  name: 'Inner Ring',
                  data: innerModifiedData1,
                  dataConfig: {
                    borderColor: ['rgb(173, 220, 124)', 'transparent'],
                    borderWidth: 2,
                    backgroundColor: ['rgb(173, 220, 124)', 'transparent'],
                  },
                },
              ]}
              options={{
                responsive: true,
                plugins: {
                  tooltip: {
                    enabled: true,
                    intersect: false,
                    mode: 'nearest',
                    callbacks: {
                      label: (item) => {
                        // Get the dataset label
                        let label = item.dataset.label || ''
                        // Get the index of the current data point
                        const dataIndex = item.dataIndex
                        console.log(item.chart.$datalabels._datasets[item.datasetIndex])

                        // Customize label based on dataset and index
                        if (item.datasetIndex === 0) {
                          // Outer ring
                          label = `${outerData[dataIndex].itemName}` // Use outer ring data
                        } else if (item.datasetIndex === 1) {
                          // Inner ring
                          if (dataIndex === 0) {
                            label = `${innerData[0]?.itemName}`
                          } else {
                            label = `` // Use inner ring label
                          }
                        }

                        return t(label) + ': ' + item.parsed * 100 + '%'
                      },
                    },
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  datalabels: {
                    formatter: (value, ctx) => {
                      let percentage = (value * 100).toFixed(2) + '%'
                      return percentage
                    },
                    color: '#fff',
                    font: {
                      size: 13,
                      weight: 'bold',
                    },
                  },
                },
              }}></DoughnutChart>
          )}
        </Grid>
      </Grid>
    </>
    // <SwitchTabs data={data} />
  )
}

export default DashboardProjectRecyclingRate
