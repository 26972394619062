import MultiTypeDatasetChart, {
  generalChartDatasetProps,
} from '@components/chart/MultiTypeDatasetChart'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme, useMediaQuery } from '@mui/material'

const Type = ({
  getValues,
  settings,
  StyledCard,
  StyledBox,
}: {
  getValues: any
  settings: any
  StyledCard: any
  StyledBox: any
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'))

  let inertWasteData: generalChartDatasetProps[] = []
  let nonInertWasteData: generalChartDatasetProps[] = []
  const values = getValues()
  const resKey = 'cdWasteGenerationByType'
  const resData = values?.project?.[resKey]?.datasets
  const hideChart = values?.project?.[resKey]?.showChart === false
  const message = values?.project?.[resKey]?.message ?? ''
  const chartSettings = settings?.[resKey]
  const groupData = {
    Inert: {
      data: resData?.find((item) => item.groupName === 'Inert')?.data,
      settings: chartSettings?.['Inert'],
    },
    'Non-Inert': {
      data: resData?.find((item) => item.groupName === 'Non-Inert')?.data,
      settings: chartSettings?.['Non-Inert'],
    },
  }
  const getGroupData = (groupName: string) => {
    return groupData[groupName] || { data: [], settings: [] }
  }
  const processChartData = (groupName: string, wasteData: any[]) => {
    const { data, settings } = getGroupData(groupName)
    data?.map((item, index) => {
      let obj: generalChartDatasetProps = {
        type: 'bar',
        label: t(item.itemName),
        data: { [t(groupName)]: item.itemNo }, // actual value
        yAxisID: 'y',
        borderColor: settings?.[index]?.borderColor,
        backgroundColor: settings?.[index]?.backgroundColor,
      }
      wasteData.push(obj)
    })
  }

  processChartData('Inert', inertWasteData)
  processChartData('Non-Inert', nonInertWasteData)

  let multiTypeDatasetChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        reverse: true,
        position: 'bottom',
        align: 'start',
        labels: {
          boxWidth: 10,
        },
        fullSize: false, // set fullSize to false
      },
      //TODO:  Add a custom plugin to display the total value on top of each bar
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
      },
    },
  }

  return (
    <>
      <h2>{t('dashboard:totalCDByType')}</h2>
      <Grid container md={12} xs={12}>
        {hideChart ? (
          <>{t(message)}</>
        ) : (
          <>
            <Grid item md={6} xs={12}>
              <StyledBox>
                <MultiTypeDatasetChart
                  eleBefore={
                    <>
                      <span>{t('common:units.tonnes')}</span>
                    </>
                  }
                  chartWrapperSX={{
                    width: '100%',
                    minHeight: 400,
                    margin: 'auto',
                  }}
                  datasets={inertWasteData}
                  options={multiTypeDatasetChartOptions}></MultiTypeDatasetChart>
              </StyledBox>
            </Grid>
            <Grid item md={6} xs={12}>
              <StyledBox>
                <MultiTypeDatasetChart
                  eleBefore={
                    <>
                      <span>{t('common:units.tonnes')}</span>
                    </>
                  }
                  chartWrapperSX={{
                    width: '100%',
                    minHeight: 400,
                    margin: '0',
                  }}
                  datasets={nonInertWasteData}
                  options={multiTypeDatasetChartOptions}></MultiTypeDatasetChart>
              </StyledBox>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}
export default Type
