import { basicChartConfig, basicChartOption } from '../BasicChart'
import DoughnutChart from './DoughnutChart'
import PieChart from './PieChart'
import { useTranslation } from 'react-i18next'
export interface basicPieChartConfig extends basicChartConfig {
  xAxisID?: string
  yAxisID?: string
  spanGaps?: boolean | number //default false
  showLine?: boolean //default true
  fill?: boolean | boolean[]
}

interface basicPieChartDataset {
  name?: string
  data: (number | null)[]
  dataConfig?: basicChartConfig
}

export interface basicPieChartProps extends pieChartProps {
  type: string
}

export interface pieChartProps {
  options?: any //basicChartOption
  labels: string[]
  datasets: basicPieChartDataset[]
}

const BasicPieChart = (props: basicPieChartProps) => {
  const { t } = useTranslation()
  const translatedLabels = props.labels?.map((label) => t(label))

  switch (props.type) {
    default:
      return (
        <PieChart
          labels={translatedLabels}
          datasets={props.datasets}
          options={props.options}></PieChart>
      )
    case 'doughnut':
      console.log(props.datasets, 'dataseta')

      return (
        <DoughnutChart
          labels={translatedLabels}
          datasets={props.datasets}
          // labels={labels}
          // datasets={datasets}
          options={props.options}></DoughnutChart>
      )
  }
}
export default BasicPieChart
